body {
  margin: 0;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: keep-all;
}

body * {
  white-space: pre-line;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.avatar:hover {
  border: 1px solid #ff5033;
}

input::placeholder {
  color:#aeaeae
}

input:focus {
  outline: none;
}

input[type="file"] {
  visibility: hidden;
  width:0px;
  height:0px;
}

textarea:focus {
  outline: none;
}


a {
  text-decoration: none;
  color: inherit;
}

.react-flow__attribution {
  display: none;
}

.react-flow__edge-path {
  stroke:#FBBC05 !important;
  stroke-dasharray: 5;
}

ul {
  list-style: none;
  margin: 0;
}